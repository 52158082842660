import Layout from "../components/base/layout/layout";
import {getObject} from "./ObjectUtil";
import {Constants, getBackendUrl} from "./Constants";
import { Templates} from "../components/base/templates";
import {
    categoriesProps,
    latestProps,
    popularDownloadsBlockProps,
    popularDownloadsProps,
    productFamilyCategoriesProps,
    Resources
} from "../pageLayout/resources";
import { pressReleasesCategoriesProps } from "../components/press-releases/press-releases";
import {
    fetchData,
    fetchDataApiV2,
    getValueForPopularAndRelatedBlock,
    isBlockPopular,
    isBlockRelated
} from "./DataUtil";
import { useEffect, useState } from "react";
import {GlobalSearchPage} from "../pageLayout/global-search-page";
import {IndividualProductTemplate} from "../components/base/individual-product-templates";
import dynamic from "next/dynamic";
import {ProductFamilyTemplate} from "../components/base/product-family-template";
import Errors from "../pageLayout/errors";

const Home = dynamic(() => import('../pageLayout/home'));
const Region = dynamic(() => import('../components/base/region'));
const Metro = dynamic(() => import('../components/base/metro'));
const Facility = dynamic(() => import('../components/base/facility'));
const Locations = dynamic(() => import('../components/base/location'));
const PressReleases = dynamic(() => import('../components/press-releases/press-releases'));

export function getPageHtml(props) {
    return(
        <Layout
            contentType={props?.data?.content_type} 
            urlAlias={props?.urlAlias} 
            data={props?.data} 
            store={props?.store} 
            lang={props?.lang} 
            meta={getObject(props, 'meta_tags')} 
            menuData={props.menuData ? props.menuData : props.props?.menuData} 
            // menuData={data.menuData} 
            countryData={props?.countryData ? props.countryData : null} 
            footerData={props ? props.footerData : undefined} 
            globalMetrics={props.globalMetrics} 
            endpoint={props.endpoint}
        >
            {getHtmlForContentType(props)}
        </Layout>
    )
}

// OLD GET PAGE PROPS
// export async function getPageProps(context, store, adminCookie) {
//     let urlAlias = '';
//     let first = true;
    
//     let lang = '';
//     let host = '';

//     if (store){
//         lang = store.getState().language.language;
//         host = store.getState().host.host;
//     }else{
//         const { req } = context;

//         if (req) {
//             host = req.headers.host
//         }
//         lang = getLanguage(host);
//     }

//     for (const [key, value] of Object.entries(context.query)) {
//         if (!key.includes('url')){
//             continue;
//         }

//         urlAlias += first === true ? value : '/' + value;
//         first = false;
//     }

//     if (!urlAlias.includes("dlrpreview")){
//         urlAlias = urlAlias.split('/').map(encodeURIComponent).join('/');
//     }

//     if (urlAlias === ''){
//         urlAlias = 'home'
//     }

//     const endpoint = getBackendUrl(host);

//     let data = await fetchDataApiV2(endpoint,"url-aliasv2?alias=/" + urlAlias + '&langcode=' + lang);

//     if(data?.status == 404 ){
//         data = await fetchDataApiV2(endpoint,"url-aliasv2?alias=/" + '404' + '&langcode=' + lang);
//     }else if(data?.status){
//         throw new Error(`Error with status code ${data.status}`);
//     }

//     if(data.published == '0' && (typeof adminCookie === 'undefined' || adminCookie === '{}')){
//         data = await fetchDataApiV2(endpoint,"url-aliasv2?alias=/" + '403' + '&langcode=' + lang);
//     }

//     if (data.redirect_page_url && data.redirect_page_url !== ''){
//         return {
//             redirect: {
//                 destination: data.redirect_page_url,
//                 statusCode: parseInt(data.redirect_page_status ? data.redirect_page_status : 301),
//             }
//         };
//     }

//     let menuData = await fetchData(endpoint,"menu?lang=" + lang);
//     let countryData = await fetchData(endpoint,"country?lang=" + lang);
//     let dataCenters = await fetchData(endpoint, "data-center-locations-filters?lang=" + lang);
//     let footerData = {}

//     const langEndpoints = {
//         'en': 'footer?lang=en',
//         'en-gb': 'footer-uk',
//         'de': 'footer-de',
//         'fr': 'footer-fr',
//         'nl': 'footer-nl',        
//         'zh-hans': 'footer-zh-hans',
//         'ko': 'footer-ko',
//         'asia': 'footer-asia',
//         'es': 'footer-es',
//         'at': 'footer-at',
//         'ch': 'footer-ch',
//         'se': 'footer-se',
//         'dk': 'footer-dk',
//         'ie': 'footer-ie',
//         'bg': 'footer-bg',
//         'hr': 'footer-hr',
//         'be': 'footer-be',
//         'el': 'footer-el'
//     };
    
//     const endpointPath = langEndpoints[lang] || 'footer?lang=en';
    
//     footerData = await fetchData(endpoint, endpointPath);    

//     let globalMetrics = await fetchDataApiV2(endpoint,'url-alias?alias=/global-metrics-api&langcode='+ lang);

//     return getPropsForContentType(data, menuData, lang, data?.content_type ? data?.content_type : '', store, footerData, urlAlias, globalMetrics, "url-aliasv2?alias=/" + urlAlias + '&langcode=' + lang, host, endpoint, countryData, dataCenters)    
// }

// NEW GET PAGE PROPS
export async function getPageProps(context, store, adminCookie) {
    let urlAlias = '';
    let first = true;
    
    let lang = '';
    let host = '';

    if (store) {
        lang = store.getState().language.language;
        host = store.getState().host.host;
    } else {
        const { req } = context;
        if (req) {
            host = req.headers.host;
        }
        lang = getLanguage(host);
    }

    for (const [key, value] of Object.entries(context.query)) {
        if (key.includes('url')) {
            urlAlias += first ? value : '/' + value;
            first = false;
        }
    }

    if (!urlAlias.includes("dlrpreview")) {
        urlAlias = urlAlias.split('/').map(encodeURIComponent).join('/');
    }

    if (!urlAlias) {
        urlAlias = 'home';
    }

    const endpoint = getBackendUrl(host);
    const urlAliasPath = `url-aliasv2?alias=/${urlAlias}&langcode=${lang}`;

    let data = await fetchDataApiV2(endpoint, urlAliasPath);

    if (data?.status === 404) {
        data = await fetchDataApiV2(endpoint, `url-aliasv2?alias=/404&langcode=${lang}`);
    } else if (data?.status) {
        throw new Error(`Error with status code ${data.status}`);
    }

    if (data.published === '0' && (typeof adminCookie === 'undefined' || adminCookie === '{}')) {
        data = await fetchDataApiV2(endpoint, `url-aliasv2?alias=/403&langcode=${lang}`);
    }

    if (data.redirect_page_url) {
        return {
            redirect: {
                destination: data.redirect_page_url,
                statusCode: parseInt(data.redirect_page_status || 301, 10),
            }
        };
    }

    const [menuData, countryData, dataCenters, footerData, globalMetrics] = await Promise.all([
        fetchData(endpoint, `menu?lang=${lang}`),
        fetchData(endpoint, `country?lang=${lang}`),
        fetchData(endpoint, `data-center-locations-filters?lang=${lang}`),
        fetchData(endpoint, getFooterEndpoint(lang)),
        fetchDataApiV2(endpoint, 'url-alias?alias=/global-metrics-api&langcode=' + lang)
    ]);

    return getPropsForContentType(
        data, menuData, lang, data?.content_type || '', store, footerData, urlAlias, globalMetrics, urlAliasPath, host, endpoint, countryData, dataCenters
    );
}

function getFooterEndpoint(lang) {
    const langEndpoints = {
        'en': 'footer?lang=en',
        'en-gb': 'footer-uk',
        'de': 'footer-de',
        'fr': 'footer-fr',
        'nl': 'footer-nl',
        'zh-hans': 'footer-zh-hans',
        'ko': 'footer-ko',
        'asia': 'footer-asia',
        'es': 'footer-es',
        'at': 'footer-at',
        'ch': 'footer-ch',
        'se': 'footer-se',
        'dk': 'footer-dk',
        'ie': 'footer-ie',
        'bg': 'footer-bg',
        'hr': 'footer-hr',
        'be': 'footer-be',
        'el': 'footer-el'
    };

    return langEndpoints[lang] || 'footer?lang=en';
}


function getHtmlForContentType(data){
    // const [width, setWidth] = useState(typeof document !== 'undefined' ? document.getElementsByTagName('main')[0]?.offsetWidth : 0)
    const [width, setWidth] = useState(0);

    useEffect(() => {
        typeof document !== 'undefined' && setWidth(document.getElementsByTagName('main')[0]?.offsetWidth);
    }, []);

    useEffect(()=> {
        resize()
    })

    const resize = () => {
        window.onresize = function(event) {
            setWidth(typeof document !== 'undefined' ? document.getElementsByTagName('main')[0]?.offsetWidth : 0)
        };
    }    
    if (data.type === 'location'){
        return <Facility tags={data.tags} related_content={data.related_content} popular_downloads_posts={data.popular_downloads_posts} width={width} global={data.globalMetrics} lang={data.lang} data={data.data} type={data.type} />
    }else if (data.type === 'home'){
        return <Home related_content={data.related_content} popular_downloads_posts={data.popular_downloads_posts} global={data.globalMetrics} data={data.data} lang={data.lang} width={width} type={data.type} />
    }else if (data.type === 'resources_page') {        
        return <Resources posts={data.resource_popular_downloads_posts} global={data.globalMetrics} data={data.data} type={data.type} width={width}/>
    }else if (data.type === 'global_search_page') {
        return <GlobalSearchPage globalMetrics={data.globalMetrics} data={data.data} type={data.type} width={width}/>
    }else if ((data.type === 'gated_template') || (data.type === 'media_template') ||
            (data.type === 'solution_template') || (data.type === 'non_gated_template')|| (data.type === 'press_release')|| (data.type === 'events')|| (data.type === 'case_study_dynamic')) {
        return <Templates tags={data.tags} related_content={data.related_content} popular_downloads_posts={data.popular_downloads_posts} width={width} global={data.globalMetrics} lang={data.lang} data={data.data} type={data.type}/>
    }else if (data.type === 'metro'){
        return <Metro metros={data.metros} global={data.globalMetrics} tags={data.tags} related_content={data.related_content} popular_downloads_posts={data.popular_downloads_posts} data={data.data} lang={data.lang} width={width} />
    }else if ((data.type === 'individual_product_template')  || (data.type === 'content_template')|| (data.type === 'newsroom') || (data.type === 'info_template') || (data.type === 'freestyle_template')){
        return <IndividualProductTemplate related_content={data.related_content} popular_downloads_posts={data.popular_downloads_posts} global={data.globalMetrics} data={data.data} lang={data.lang} width={width} type={data.type} />
    }else if (data.type === 'product_family_template'){
        return <ProductFamilyTemplate related_content={data.related_content} popular_downloads_posts={data.popular_downloads_posts} global={data.globalMetrics} data={data.data} lang={data.lang} width={width} type={data.type} />
    }else if (data.type === 'region'){
        return <Region metros={data.metros} tags={data.tags} related_content={data.related_content} popular_downloads_posts={data.popular_downloads_posts} global={data.globalMetrics} data={data.data} lang={data.lang} width={width} dataCenters={data.dataCenters} />
    }else if (data.type === 'global_data_centre_locations'){        
        return <Locations global={data.globalMetrics} data={data.data} lang={data.lang} width={width} related_content={data.related_content} popular_downloads_posts={data.popular_downloads_posts} dataCenters={data.dataCenters}/>
    }else if(data.type === 'press_releases_page'){
        return <PressReleases global={data.globalMetrics} data={data.data} lang={data.lang} width={width} />
        // return <PressReleasesPage data={data.data} lang={data.lang} width={width} />
    }else if (data.type === 'error'){
        return <Errors global={data.globalMetrics} data={data.data} lang={data.lang}/>
    }
}

async function getPropsForContentType(data, menuData, lang, type, store, footerData, urlAlias, globalMetrics, l, host, endpoint, countryData, dataCenters){
    let ret = {};
    let metros = null;
    if (data?.content_type === 'location' || data?.content_type === 'facility' || data?.content_type === 'global_data_centre_locations' || data?.content_type === 'error'){        
        ret = data;
    }else if(data?.content_type === 'metro' || data?.content_type === 'region'){
        ret = data;
        metros = await new Promise((resolve, reject) => {
            resolve(allMetrosProps(endpoint,lang));
        })
    }
    else if (data?.content_type === 'blog_flexible'){
        ret = data;
    }else if (data?.content_type === 'resources_page'){
        ret = {
            categories : await new Promise((resolve, reject) => {
                resolve(categoriesProps(endpoint,lang));
            }),
            field_featured_title : data.field_featured_content?.length > 0 ? data.field_featured_content[0]?.featured_content_block_type?.field_title_color : null,
            field_filter_resources : data.field_filter_resources,
            field_latest_insights : data.field_latest_insights,
            field_popular_downloads : data.field_popular_downloads,
            field_resources_subtitle : data.field_resources_subtitle,
            field_resources_title : data.field_resources_title,
            field_search_resources : data.field_search_resources,
            field_results_label: data?.field_results_label || '',
            field_no_results_text: data?.field_no_results_text || '',
            field_clear_all_text: data?.field_clear_all_text || '',
            field_most_popular_text: data?.field_most_popular_text || '',
            featured : data.field_featured_content?.length > 0 ? data.field_featured_content[0]?.featured_content_block_type?.field_featured_posts : [],
            latest : await new Promise((resolve, reject) => {
                resolve(latestProps(endpoint,lang));
            }),
            mutlimedia_block: data.field_watch_listen_section,
            translang: data.translang,            
        }
    }else if(data?.content_type === 'press_releases_page'){
        ret = {
            categories : await new Promise((resolve, reject) => {
                resolve(pressReleasesCategoriesProps(endpoint,lang));
            }),
            ...data
        }
    }
    else if (data?.content_type === 'global_search_page'){        
        ret = {
            categories : await new Promise((resolve, reject) => {
                resolve(categoriesProps(endpoint,lang));
            }),
            field_filter_resources : data?.field_filter_resources || '',
            field_resources_title : data?.field_resources_title || '',
            field_resources_subtitle : data?.field_resources_subtitle || '',
            field_search_resources : data?.field_search_resources || '',            
            field_results_label: data?.field_results_label || '',
            field_no_results_text: data?.field_no_results_text || '',
            field_clear_all_text: data?.field_clear_all_text || '',
            field_most_popular_text: data?.field_most_popular_text || '',
            translang: data.translang,            
        }
    }else if ((data?.content_type === 'gated_template') || (data?.content_type === 'media_template')|| (data?.content_type === 'press_release')|| (data?.content_type === 'events')|| (data?.content_type === 'case_study_dynamic') ||
        (data?.content_type === 'solution_template') || (data?.content_type === 'non_gated_template') || (data?.content_type === 'content_template') || (data?.content_type === 'info_template')|| (data?.content_type === 'freestyle_template')){
        ret = {
            categories : await new Promise((resolve, reject) => {
                resolve(productFamilyCategoriesProps(endpoint,lang));
            }),
            ...data
        }
    }else if (data?.content_type === 'home' || data?.content_type === 'individual_product_template' || data?.content_type === 'newsroom'){
        ret = {
            categories : await new Promise((resolve, reject) => {
                resolve(productFamilyCategoriesProps(endpoint,lang));
            }),
            ...data
        }
    }else if (data?.content_type === 'product_family_template'){
        ret = {
            categories : await new Promise((resolve, reject) => {
                resolve(productFamilyCategoriesProps(endpoint,lang));
            }),
            ...data
        }
    }

    // ret.published = data.published
    ret.published = data?.published ? data?.published : false;

    let popularDupes = '';
    let relatedDupes = '';

    let manualPopular = getValueForPopularAndRelatedBlock(ret.field_template_blocks, 1)[0]?.field_related_manual_content;
    let popularLimit = getValueForPopularAndRelatedBlock(ret.field_template_blocks, 1)[0]?.field_related_content_item_limit[0]?.value;

    let manualRelated = getValueForPopularAndRelatedBlock(ret.field_template_blocks, 0)[0]?.field_related_manual_content;
    let relatedLimit = getValueForPopularAndRelatedBlock(ret.field_template_blocks, 0)[0]?.field_related_content_item_limit[0]?.value;

    if (typeof manualPopular !== 'undefined'){
        manualPopular.forEach((item, index) => {
            if (index === 0){
                popularDupes += item?.node_id
                return
            }

            popularDupes += ',' + item?.node_id
        })
    }

    if (popularDupes === ''){
        popularDupes += ret.node_id;
    }else {
        popularDupes += ',' + ret.node_id;
    }

    if (typeof manualRelated !== 'undefined'){
        manualRelated.forEach((item, index) => {
            if (!item){
                return
            }
            if (index === 0){
                relatedDupes += item.node_id
                return
            }

            relatedDupes += ',' + item.node_id
        })
    }

    if (relatedDupes === ''){
        relatedDupes += ret.node_id;
    }else {
        relatedDupes += ',' + ret.node_id;
    }

    if (typeof relatedLimit === 'undefined'){
        relatedLimit = 0
    }

    if (typeof popularLimit === 'undefined'){
        popularLimit = 0
    }

    let categories = getAllCategories(ret.field_template_category, ret.field_template_secondary_categor, ret.field_template_tertiary_category);

    return {
        props :
            {
                relatedLimit : relatedLimit,
                data : ret,
                metros,
                tags : ret.field_template_category ? ret.field_template_category.concat(ret.field_template_secondary_categor) : [],
                popular_downloads_posts :
                    (typeof manualPopular !== 'undefined' && manualPopular.length == popularLimit)
                        ?
                        manualPopular
                        :
                        (
                            (typeof manualPopular !== 'undefined' && manualPopular.length != popularLimit)
                                ?
                                manualPopular.concat(await new Promise((resolve, reject) => {
                                    resolve(popularDownloadsBlockProps(
                                        endpoint,
                                        lang,
                                        categories,
                                        popularLimit - (manualPopular ? manualPopular.length : 0),
                                        'popular',
                                        popularDupes))
                                }))
                                :
                                (isBlockPopular(getValueForPopularAndRelatedBlock(ret.field_template_blocks, 1)) ?
                                    await new Promise((resolve, reject) => {
                                        resolve(popularDownloadsBlockProps(
                                            endpoint,
                                            lang,
                                            categories,
                                            getValueForPopularAndRelatedBlock(ret.field_template_blocks, 1)[0]?.field_related_content_item_limit[0].value,
                                            'popular',
                                            popularDupes))
                                    })
                                    :
                                    [])
                        ) ,
                resource_popular_downloads_posts :
                    data?.content_type === 'resources_page'
                    ?
                    await new Promise((resolve, reject) => {
                        resolve(popularDownloadsProps(endpoint,lang))
                    })
                    :
                    [],
                related_content :
                    (typeof manualRelated !== 'undefined' && manualRelated.length == relatedLimit)
                    ?
                    manualRelated
                    :
                    (
                        (typeof manualRelated !== 'undefined' && manualRelated.length != relatedLimit)
                        ?
                        manualRelated.concat(await new Promise((resolve, reject) => {
                            resolve(popularDownloadsBlockProps(
                                endpoint,
                                lang,
                                categories,
                                relatedLimit - manualRelated.length,
                                'related',
                                    relatedDupes))
                        }))
                        :
                        (isBlockRelated(getValueForPopularAndRelatedBlock(ret.field_template_blocks, 0)) ?
                        await new Promise((resolve, reject) => {
                            resolve(popularDownloadsBlockProps(
                                endpoint,
                                lang,
                                categories,
                                relatedLimit,
                                'related',
                                relatedDupes))
                        })
                        :
                        [])
                    )
                ,
                urlAlias : urlAlias,
                menuData : menuData,
                lang : lang,
                type : type,
                footerData: footerData,
                globalMetrics: globalMetrics,
                countryData: countryData?.length > 0 ? countryData : [],
                endpoint,
                dataCenters: dataCenters
            }
    };
}

const getAllCategories = (first, second, third) => {
    let tids = ''
    let wasFirst = false;

    first?.forEach((item, index) => {
        if (!item){
            return
        }
        if (!wasFirst){
            wasFirst = true
            tids += item.tid
            return
        }

        tids += ',' + item.tid
    })

    second?.forEach((item, index) => {
        if (!item){
            return
        }
        if (!wasFirst){
            wasFirst = true
            tids += item.tid
            return
        }
        tids += ',' + item.tid
    })

    third?.forEach((item, index) => {
        if (!item){
            return
        }
        if (!wasFirst){
            wasFirst = true
            tids += item.tid
            return
        }
        tids += ',' + item.tid
    })

    return tids
}
export function getLanguage(host) {
    let ret = 'en';

    if (host.includes('localhost')) {
        return ret;
    }
    if (host.includes('ch.digitalrealty.com')) {
        return 'ch';
    }
    const domain = host.includes('-') ? host.split('-').pop().split('.')[0] : host.split('.').pop().toLowerCase();

    const domainLanguageMap = {
        'de': 'de',
        'nl': 'nl',
        'fr': 'fr',
        'es': 'es',
        'jp': 'ja',
        'com': 'en',
        'co': 'en',              
        'uk': 'en-gb',           
        'frontend': 'en',
        'uat': 'en',
        'uat-de': 'de',
        'dlr-fr': 'fr',
        'kr':'ko',
        'cn':'zh-hans',
        'asia': 'asia',
        'ch': 'ch',
        'at': 'at',
        'se': 'se',
        'dk': 'dk', 
        'ie': 'ie',
        'hr': 'hr',
        'be': 'be',
        'gr': 'el',
    };

    if (domain in domainLanguageMap) {
        ret = domainLanguageMap[domain];
    }


    return ret;
}

export const allMetrosProps = async (endpoint,lang) => {
    const res = await fetch(endpoint.apiV2Url + `url-aliasv2?alias=/data-centers&langcode=${lang}`, Constants.headers);
    let data = await res.json();
    return data?.metros;
}

export const smoothScroll = () => {
    if(typeof  window !== 'undefined'){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

export const smoothScrollToTopOfFilters = () => {
    if(typeof  window !== 'undefined'){
        let filters = document.querySelector('.filter-container');
        const y = filters.getBoundingClientRect().top + window.pageYOffset - 157;
        window.scrollTo({top: y, behavior: 'smooth'});
    }
}

export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== 'undefined') {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

const returnProps = () => {
    return {
        props :
            {
                data : [],
                tags : [],
                popular_downloads_posts : [],
                resource_popular_downloads_posts : [],
                related_content :[],
                urlAlias : [],
                menuData : [],
                lang : [],
                type : 0,
                footerData: [],
                globalMetrics: []
            }
    };

}
