import { useState, useEffect, Fragment } from "react";
import ResourceFilter from '../resources/resource-filter';
import { renderDataCentreBlocks } from "../../util/BlockUtil";
import { 
    isItUndefined, 
    fetchData, 
    assemble, 
    createFilterPressReleases, 
    getAlgoliaDatasetValue, 
    addQueryParamWithoutRedirecting, 
    marketoOptions as options, 
    getAlgoliaIndex, 
    unixToDateString, 
    correctUrlFormatForRouting, 
    handleGoogleTagManager,
    addUserDataToSessionStorage,
    setSessionStorageUser,
    addUserDataToCookies,
    setCookieUser
} from "../../util/DataUtil";
import { getLanguage } from "../../util/PageUtil";
import { CircularProgress } from '@mui/material';
import {useRouter} from "next/router";
import { Each } from '../../util/Each'

const PressReleases = ({data, lang, width, global, popular_downloads_posts, related_content, tags}) => {
    const router = useRouter();
    const [query, setQuery] = useState([]);
    const [page, setPage] = useState(0);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);
    const [facetFilters, setFacetFilters] = useState([]);
    const [filterProps, setFilterProps] = useState([]);
    const [showMore, setShowMore] = useState([]);
    const [showMoreLocal, setShowMoreLocal] = useState([]);
    const [showMoreGlobal, setShowMoreGlobal] = useState([]);
    const [closeFilters, setCloseFilters] = useState(false);
    const [applyFilter, setApplyFilter] = useState(false);
    const [languageFilter, setLanguageFilter] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [results, setResults] = useState(false);
    const [algoliaData, setAlgoliaData] = useState([]);
    const [algoliaDataLocal, setAlgoliaDataLocal] = useState([]);
    const [algoliaDataGlobal, setAlgoliaDataGlobal] = useState([]);
    const [localFilter, setLocalFilter] = useState(lang !== 'en');
    const [triggerLocalFilter, setTriggerLocalFilter] = useState(false);

    let hitsPerPage = 16;
    let lng = typeof window !== 'undefined' ? getLanguage(window.location.hostname) : "";
    let facetFiltersArray = [];
    let lngArray = [];

    useEffect(() => {
        if(!lng){
            return;
        }

        setIsLoading(true);
        // fetchAlgoliaData();

        // if(lng !== 'en'){
        //     fetchAlgoliaData(lng);
        // }

        fetchAlgoliaData(lng);
        // getMostPopularSearchTerms();
    }, [applyFilter, query, filterProps, triggerLocalFilter]);

    useEffect(() => {
        if(!lng){
            return;
        }

        if(page > 0){
            fetchAlgoliaPagination();

            if(lng !== 'en'){
                fetchAlgoliaPagination(lng);
            }
        }
    }, [page]);

    useEffect(() => {
        const { type, category } = router.query;
        const localFilter = type !== undefined ? type : category !== undefined ? category : '';
        let url = '';
    
        if (typeof window !== 'undefined') {
            url = window.location.pathname;
            localFilter.length > 0 ? triggerFilterFromRedirect([{ name: localFilter }]) : clearAll();
        }
    
        const defaultYearToDisplay = isItUndefined(data?.field_default_year_to_display, 'name');
        
        if (isItUndefined(defaultYearToDisplay)) {
            addQueryParamWithoutRedirecting(router, url, defaultYearToDisplay, 'type');
            triggerFilterFromRedirect([{ name: defaultYearToDisplay }]);
        }

        // setCookieUser();
    }, []);

    const triggerFilterFromRedirect = (filter) => {
        for(let item of data?.categories){
            // if(item.field_source_translation.toLowerCase().replace("&amp;", "&") === filter[0].name.toLowerCase()){
            if(item.name.toLowerCase().replace("&amp;", "&") === filter[0].name.toLowerCase()){
                triggerFilter([item]);
            }
        }
        setApplyFilter(true);
        // setQuery([]);
    }

    const fetchAlgoliaData = async (lng = 'en') => {
        setResults((query.length || facetFilters.length || languageFilter.length) !== 0);
    
        const createFetchRequest = (lng, context) => {
            return createFilterPressReleases(
                lngArray,
                facetFiltersArray,
                facetFilters,
                query,
                hitsPerPage,
                page,
                lng,
                context,
                getAlgoliaDatasetValue(3) + getAlgoliaIndex()
            );
        };
    
        const fetchAlgolia = async (url, setData, setShowMoreFunc) => {
            try {
                const response = await fetch(url, options);
                const newResponse = await response.json();
                setShowMoreFunc(newResponse.hits);
                setData(newResponse);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        const url = createFetchRequest('en', undefined);
        const urlLocal = createFetchRequest(lng, 'local');
        const urlGlobal = createFetchRequest(lng, 'global');
    
        await Promise.all([
            fetchAlgolia(url, setAlgoliaData, setShowMore),
            fetchAlgolia(urlLocal, setAlgoliaDataLocal, setShowMoreLocal),
            fetchAlgolia(urlGlobal, setAlgoliaDataGlobal, setShowMoreGlobal)
        ]);
    };
    
    const fetchAlgoliaPagination = async (lng = 'en') => {
        const url = createFilterPressReleases(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, 'en', undefined, getAlgoliaDatasetValue(3) + getAlgoliaIndex());
        const urlLocal = createFilterPressReleases(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, 'local', getAlgoliaDatasetValue(3) + getAlgoliaIndex());
        const urlGlobal = createFilterPressReleases(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, 'global', getAlgoliaDatasetValue(3) + getAlgoliaIndex());
    
        const fetchAlgolia = async (url, setShowMoreFunc, setAlgoliaDataFunc) => {
            try {
                const response = await fetch(url, options);
                const newResponse = await response.json();
                setShowMoreFunc(previousResponse => [...previousResponse, ...newResponse.hits]);
                setAlgoliaDataFunc(newResponse);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        await Promise.all([
            fetchAlgolia(url, setShowMore, setAlgoliaData),
            fetchAlgolia(urlLocal, setShowMoreLocal, setAlgoliaDataLocal),
            fetchAlgolia(urlGlobal, setShowMoreGlobal, setAlgoliaDataGlobal)
        ]);
    };

    const renderSearchResults = (data, length = -1) => {
        if (!data || data.length === 0) {
            return [];
        }
      
        if (length !== -1) {
            data = [...new Set(data)].slice(0, length);
        }

        return data.map(({ date, title, url, id, objectID }, index) => {
            const formattedUrl = correctUrlFormatForRouting(url);

            return (
                <a
                    href={formattedUrl?.url} 
                    className="result-item" 
                    key={`${id}-${index}`}
                    // onClick={() => addUserDataToCookies(objectID, getAlgoliaDatasetValue(3) + getAlgoliaIndex(), 'manufacturing')}
                >
                    {date && <div className="date">{unixToDateString(date)}</div>}
                    <div className="title">{title}</div>
                </a>
            );
        });
    };

    function renderResults(data){
        if(!data){
            return;
        }

        if(data.length === 0){
            return (
                <div className={'no-result'}>
                    <h2>No results were found. Please check the spelling, reset any filters and try again.</h2>
                    <div className={'clear-all'} onClick={() => { clearAll() }}>Clear all search terms.</div>
                    {/* <div className={'most-popular'}>
                        <h2>Most popular</h2>
                        <div className={'search-results'}>{renderSearchCards(topSearchItems, 12)}</div>
                    </div> */}
                </div>
            );
        }

        return renderSearchResults(data);
    }

    const clearAll = () => {
        setFilterProps([])
        triggerFilter([])
        setLanguageFilter([])
        if(query.length > 0){
            setQuery([]);
        }
        setShowMore([]);
        setCloseFilters(false)
    }

    const triggerFilter = (filters) => {
        setFilterProps(filters);

        let searchFilters = [];
        for(let filter of filters){
            searchFilters.push(filter?.field_source_translation);
        }

        setFacetFilters(searchFilters);
        setPage(0);
        setApplyFilter(false);
    }

    const clearQuery = () => {
        setQuery([]);
        setIsSearchActive(false);
        setIsFilterActive(true);
    }

    const handleSearch = (search) => {
        setQuery(search.length !== 0 ? [search] : []);
        // setFilterProps([]);
        // setFacetFilters([]);
        setPage(0);
        setApplyFilter(false);
    }
    
    const handleLocalFilterChange = (value) => {
        setLocalFilter(value)
    }

    const getFilters = (categories) => {
        return categories ? categories.filter(item => item?.parent_id?.length !== 0).sort((a, b) => a.weight.localeCompare(b?.weight)) : [];
    };
    
    const getFilterParent = (categories) => {
        return categories ? categories.filter(item => !item?.parent_id) : [];
    }

    const handleLocalFilterSelect = (event) => {
        setFacetFilters(event.target.value !== -1 ? [event.target.value.toString()] : []);
        setTriggerLocalFilter(!triggerLocalFilter);
    }

    const getFilteredAlgoliaData = (data, regionFilter) => {
        if (!data) {
            return [];
        }

        if (regionFilter === 'global') {
            return data.filter(item => item?.field_pr_original_language === 'en');
        } else if (regionFilter === 'local') {
            return data.filter(item => item?.field_pr_original_language !== 'en');
        }
    
        return data;
    }    

    return(
        <div id={'press-releases'} className={'press-releases'} >
            {renderDataCentreBlocks({data, lang, width, global, popular_downloads_posts, related_content, tags})}
            <ResourceFilter
                data={assemble(data?.categories)}
                searchQuery={query}
                setIsSearchActive={setIsSearchActive}
                isSearchActive={isSearchActive}
                // search={isItUndefined(data.field_search_resources)}
                search={data?.field_search_resources[0]?.value}
                title={isItUndefined(data?.field_filter_resources)}
                filterProps={filterProps}
                isFilterActive={isFilterActive}
                setIsFilterActive={setIsFilterActive}
                triggerFilter={triggerFilter}
                handleFilterApply={setApplyFilter}
                onSearchChange={handleSearch}
                languageFilter={languageFilter}
                setLanguageFilter={setLanguageFilter}
                setCloseFilters={setCloseFilters}
                clearQuery={clearQuery}
                cancelText={isItUndefined(global?.field_cancel_text_translation)}
                applyFilterText={isItUndefined(global?.field_apply_filters_text)}
                clearAllText={isItUndefined(global?.field_clear_all_text)}
                selectedText={isItUndefined(global?.field_selected_text)}
                seeMoreText={isItUndefined(global?.field_see_more_text)}
                seeLessText={isItUndefined(global?.field_see_less)}
                // hideFilter={lang !== 'en'}
                // hideFilter={true}
            />
            <div className="inner-container">
                {/* PRESS RELEASES UPDATE */}
                {/* {
                    // lang !== 'en' &&
                    <div className="local-filter-wrapper">
                        <div>
                            {
                                lang !== 'en' &&
                                <>
                                    <p className={localFilter ? '' : 'active'} onClick={() => {handleLocalFilterChange(false)}}>{isItUndefined(data?.field_global_filter_text)}</p> <div className="vertical-line" /> <p className={localFilter ? 'active' : ''} onClick={() => {handleLocalFilterChange(true)}}>{isItUndefined(data?.field_local_filter_text)}</p>
                                </>
                            }
                        </div>
                        <div className={'select-wrapper'}>
                            <select name="years" id="years" onChange={(event) => {handleLocalFilterSelect(event)}} >
                                <Each of={getFilterParent(data.categories)} render={(item, index) => (
                                    <option key={index} value={-1}>{item.name}</option>
                                )} />
                                <Each of={getFilters(data?.categories)} render={(item, index) => (
                                    <option key={index} value={item.name}>{item.name}</option>
                                )} />
                            </select>
                            <img src={'/images/arrow-down.svg'} alt={'arrow'}/>
                        </div>
                    </div>
                } */}
                {/* PRESS RELEASES UPDATE */}
                <p className="results-title">{isItUndefined(data.field_press_releases_title)}</p>
                <hr />
                {
                    isLoading
                    ?
                    <div className={'loader'}>
                        <CircularProgress />
                    </div>
                    :
                    <Fragment>
                        <div className={'search-results'}>
                            {/* PRESS RELEASES UPDATE */}
                            {/* {
                                localFilter
                                ?
                                // renderResults(getFilteredAlgoliaData(showMoreLocal, 'local'))
                                renderResults(showMoreLocal)
                                :
                                lang !== 'en'
                                ?
                                // renderResults(getFilteredAlgoliaData(showMoreLocal, 'global'))
                                renderResults(showMoreGlobal)
                                :
                                renderResults(showMore)
                            } */}
                            {renderResults(showMore)}
                            {/* PRESS RELEASES UPDATE */}
                        </div>
                        {/* PRESS RELEASES UPDATE */}
                        {/* {
                            localFilter
                            ?
                            (algoliaDataLocal.page + 1 !== algoliaDataLocal.nbPages && algoliaDataLocal.nbHits !== 0) &&
                            <div className={'show-more'} onClick={() => setPage(page + 1)}>Show More</div>
                            :
                            (algoliaData.page + 1 !== algoliaData.nbPages && algoliaData.nbHits !== 0) &&
                            <div className={'show-more'} onClick={() => setPage(page + 1)}>Show More</div>
                        } */}
                        {(algoliaData.page + 1 !== algoliaData.nbPages && algoliaData.nbHits !== 0) &&
                             <div className={'show-more'} onClick={() => setPage(page + 1)}>Show More</div>
                        }
                        {/* PRESS RELEASES UPDATE */}
                    </Fragment>
                }
            </div>
        </div>
    );
}

export default PressReleases;

export async function pressReleasesCategoriesProps(endpoint,lang) {
    return await fetchData(endpoint,'press-releases-categories?lang=' + lang);
}